import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import isObject from 'lodash/isObject';
import { z } from 'zod';
import { RefreshAuth } from '@/shared/api';
import { AuthErrorCode, INVALID_PROPERTIES_MESSAGE } from '@/shared/api/constants';
import { NetworkError, ValidationError, ServerError } from '@/shared/api/errors';
import { logout, logoutInactiveUser } from '@/shared/api/utils';
import { BACKEND_HOST, BASE_PREFIX } from '@/shared/config';
import { AUTH_DATA_LOCAL_STORAGE_KEY } from '@/shared/constants';
import { V3Service } from '@/shared/services/V3Service';
import { validationService } from '@/shared/services/ValidationService';
import { APIVersionService, AuthService as AuthServiceI, RequestService as RequestServiceI } from '@/shared/types';
import { isJsonString } from '@/shared/utils';
import { responseDataSchema } from '@/shared/validationSchemas';
import { AuthService } from '../AuthService';
import { RequestService } from '../RequestService';

export class ErrorHandlingService<E> {
  private refreshTokenRequestRef: null | Promise<AxiosResponse> = null;

  private refreshTokenUrl = `${BACKEND_HOST}${BASE_PREFIX}/auth/token/refresh`;

  private refreshTokenRequestSchema = responseDataSchema(
    z.object({
      accessToken: z.string(),
      refreshToken: z.string(),
    })
  );

  constructor(
    private apiService: APIVersionService<E>,
    private authService: AuthServiceI,
    private requestService: RequestServiceI
  ) {}

  getError(error: AxiosError) {
    if (this.apiService.isError(error)) {
      return error;
    }
    if (error.response) {
      const { data } = error.response;
      if (isObject(data)) {
        if ('message' in data) {
          return new Error(String(data.message));
        }
        if ('error' in data) {
          return new Error(String(data.error));
        }
        if ('errors' in data) {
          return new ValidationError(INVALID_PROPERTIES_MESSAGE, data.errors);
        }
        // any other unknown error response
        return error;
      }
    } else if (error.request) {
      return new NetworkError();
    }
    return new Error(error.message);
  }

  getParsedBlobError(error: AxiosError): Promise<AxiosError> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (isJsonString(reader.result)) {
          resolve({
            ...error,
            response: {
              ...error.response,
              data: JSON.parse(reader.result as string),
            },
          } as AxiosError);
        }
      };

      reader.readAsText(error.response?.data as Blob);
    });
  }

  async getAuthError({ error, instance }: { error: AxiosError; instance: AxiosInstance }) {
    const { config } = error;
    const code = this.authService.getAuthErrorCode(error);
    const errorMessage = this.authService.getAuthErrorMessage(error);

    if (!config) return new NetworkError();

    if (!code) return;

    if (code === AuthErrorCode.UserNotActive) {
      logoutInactiveUser();
      return new Error(errorMessage);
    }

    if (code === AuthErrorCode.UserRoleChanged) {
      logout();
      return new Error(errorMessage);
    }

    if (code === AuthErrorCode.TokenExpired) {
      const authData = this.authService.getAuthDataFromLocalStorage();
      if (!authData) {
        logout({ shouldSaveLastRoute: true });
        return new Error(errorMessage);
      }

      try {
        const refreshedData = await this.getRefreshToken({ refresh_token: authData.refreshToken });
        localStorage.setItem(AUTH_DATA_LOCAL_STORAGE_KEY, JSON.stringify(refreshedData));
        return await instance.request(
          this.requestService.getRequestConfigWithAccessTokenHeader(config, refreshedData.data.accessToken)
        );
      } catch (e) {
        logout({ shouldSaveLastRoute: true });
        return new Error(errorMessage);
      }
    }
  }

  getRefreshToken(payload: RefreshAuth) {
    if (this.refreshTokenRequestRef && this.refreshTokenRequestRef instanceof Promise) {
      return this.refreshTokenRequestRef;
    }

    const refreshTokenRequest = axios
      .post(this.refreshTokenUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        validationService.validateResponseData({
          url: this.refreshTokenUrl,
          responseData: resp.data,
          validationSchema: this.refreshTokenRequestSchema,
        });

        return resp.data;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => this.setRefreshTokenRequestRef(null));

    this.setRefreshTokenRequestRef(refreshTokenRequest);
    return refreshTokenRequest;
  }

  private setRefreshTokenRequestRef(request: Promise<AxiosResponse> | null) {
    this.refreshTokenRequestRef = request;
  }

  isNetworkError(error: unknown) {
    return error instanceof NetworkError;
  }

  isErrorCauseNetworkError(error: unknown) {
    return !!(error && typeof error === 'object' && 'cause' in error && error.cause instanceof NetworkError);
  }

  isServerError(error: unknown): error is ServerError {
    return error instanceof ServerError;
  }

  isErrorCauseServerError(error: unknown): error is { cause: ServerError } {
    return !!(error && typeof error === 'object' && 'cause' in error && error.cause instanceof ServerError);
  }
}

export const errorHandlingService = new ErrorHandlingService(
  new V3Service(),
  new AuthService(new V3Service()),
  new RequestService()
);
